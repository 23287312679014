import React, { memo } from "react"
import { Link } from "gatsby"
import { useWindowSize, useIntl } from "~hooks"
import { Disclosure } from '@headlessui/react'


const CompetencesPage = (props: any) => {
  const { toRender: isMobile } = useWindowSize(["mobile", "landscape"])
  const [intl] = useIntl()

  const renderInfoBlock = (
    path: string,
    title: string,
    description: string
  ) => {
    return (
      <div>
        <h4
          className={
            isMobile
              ? "font-bold text-[1.125rem] hover:text-text-yellow-700"
              : "font-bold text-[1.125rem] hover:text-text-yellow-700"
          }
        >
          <Link to={path}>{intl(title)}</Link>
        </h4>
        <div className={"text-[0.875rem] font-content"}>{intl(description)}</div>
      </div>
    )
  }

  return (
    <div className={"flex"}>
      <div
        className={
          isMobile
            ? "pt-[100px] text-left px-5"
            : "pl-[500px] mt-[25vh] pr-[7rem]"
        }
      >

        {isMobile && <Disclosure>
          { ({ open }) => (
            <>
              <Disclosure.Button className="">
                <div
                  className={"text-text-black text-[1.125rem] font-bold mt-[20px] text-left flex"}
                >
                  <span>{intl("HomePage.Subtitle")}</span>
                  <span className="text-[40px] text-text-red-700 ">{open ? "-" : "+"}</span>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="">
                <div className="text-text-black text-[0.875rem] font-content">
                  {intl("HomePage.Description")}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure> }


        {!isMobile && <div>
          <div
            className={"text-text-black text-[1.125rem] font-bold leading-[normal] mb-[7px]"
            }
          >
            {intl("HomePage.Subtitle")}
          </div>


          <div className="text-text-black text-[0.875rem] font-content">
            {intl("HomePage.Description")}
          </div>

        </div>}

        <div
          className={
            isMobile
              ? "grid text-text-black mt-[40px] gap-y-[40px]"
              : "grid grid-cols-2 text-text-black mt-[40px] gap-x-[50px]"
          }
        >
          {renderInfoBlock(
            "/competences/fiscal",
            "HomePage.Fiscal",
            "HomePage.Fiscal.Description"
          )}
          {renderInfoBlock(
            "/competences/commercial",
            "HomePage.Commercial",
            "HomePage.Commercial.Description"
          )}
        </div>
        <div
          className={
            isMobile
              ? "grid text-text-black mt-[30px] gap-y-[40px]"
              : "grid grid-cols-2 text-text-black mt-[30px] gap-x-[50px]"
          }
        >
          {renderInfoBlock(
            "/competences/travail-assurances",
            "HomePage.Droit",
            "HomePage.Droit.Description"
          )}
          {renderInfoBlock(
            "/competences/real-estate-environment",
            "HomePage.Immobilier",
            "HomePage.Immobilier.Description"
          )}
        </div>
        <div
          className={
            isMobile
              ? "grid text-text-black mt-[30px] gap-y-[40px]"
              : "grid grid-cols-2 text-text-black mt-[30px] gap-x-[50px]"
          }
        >
          {renderInfoBlock(
            "/competences/clientele-privee-successions",
            "HomePage.Clientele",
            "HomePage.Clientele.Description"
          )}
          {renderInfoBlock(
            "/competences/negociations-litiges",
            "HomePage.Resolution",
            "HomePage.Resolution.Description"
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(CompetencesPage)
